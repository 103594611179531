import { getItem } from '../../../api/api-methods';
import { numberFormatMX } from '../../shared/helpers/dataUtils';
import { fetchGetGroupByStudent } from '../helpers/utilsStudent';

export const StudentUtils = {
  data() {
    return {
      avatares: ['ardilla.png', 'castor.png', 'conejo.png', 'mapache.png'],
    };
  },
  methods: {
    async studentConstructor(studentId, studentObj, institution) {
      try {
        const student = studentObj
          ? studentObj
          : await getItem(`/app-personas/alumno/${studentId}`);
        const { datos_personales, id, colegiatura, nivel_educativo } = student;
        const fullName = this.getFullName(datos_personales);
        if (!student.grupos) {
          // console.log('Alumnos sin array grupos');

          const groupfetch = await this.getStudentGroup(student, institution);
          return {
            id,
            groupId: groupfetch.id,
            avatar: groupfetch.imagen_grupo && groupfetch.imagen_grupo.imagen,
            familia: {},
            tuition: colegiatura
              ? numberFormatMX.format(colegiatura.monto)
              : 'Sin asignar',
            ...(fullName && { nombre: fullName }),
            ...(groupfetch &&
              groupfetch.nombre_grupo && { grupo: groupfetch.nombre_grupo }),
            ...student,
          };
        }
        // console.log('Alumnos con array grupos');

        const group =
          student.grupos && student.grupos.length > 0
            ? student.grupos[0]
            : { id: null, nombre_grupo: 'Sin asignar', url_imagen: null };
        const groupImage = this.getGroupImage(group);

        // console.log('Con array grupos group', group);

        return {
          id,
          groupId: group.id,
          avatar: groupImage,
          familia: {},
          tuition: colegiatura
            ? numberFormatMX.format(colegiatura.monto)
            : 'Sin asignar',
          levelName: nivel_educativo ? nivel_educativo.nombre : 'Sin asignar',
          ...(fullName && { nombre: fullName }),
          ...(group && group.nombre_grupo && { grupo: group.nombre_grupo }),
          ...student,
        };
      } catch (error) {
        console.log(error);
      }
    },
    getGroupImage(group) {
      if (
        !group ||
        !group.url_imagen ||
        group.url_imagen === 'No hay imagen disponible'
      ) {
        return 'huellita.png';
      }
      return group.url_imagen;
    },
    getFullName(personalData) {
      const {
        primer_nombre,
        segundo_nombre,
        apellido_paterno,
        apellido_materno,
      } = personalData;
      const firstName = primer_nombre || '';
      const secondName = segundo_nombre || '';
      const lastName1 = apellido_paterno || '';
      const lastName2 = apellido_materno || '';
      return `${firstName} ${secondName} ${lastName1} ${lastName2}`.trim();
    },
    async getStudentGroup(student, institution) {
      const group = await fetchGetGroupByStudent(student.id, institution);
      // console.log('getStudentGroup', group);

      return group.results.length > 0
        ? group.results[0]
        : { nombre_grupo: 'Sin asignar', id: null };
    },
  },
};
