<template>
  <v-row
    class="mb-3"
    justify="center"
  >
    <v-col
      cols="2"
      md="1"
      lg="1"
      xl="1"
    >
      <v-btn
        icon
        color="primary"
        title="Regresar"
        @click="returnToTable"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </v-col>
    <v-col  >
      <h2>{{ title }}</h2>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    routerName: {
      type: String,
      default: null
    }
  },
  methods: {
    returnToTable() {
      this.$router.replace({ name: this.routerName })
    }
  }
}
</script>
