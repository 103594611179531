import { api } from '../../../api/axios-base';
export const handleStudentGroupChange = async (
  studentId,
  previousGroup,
  newGroup,
  reasonChange
) => {
  try {
    console.log(studentId, previousGroup, newGroup, reasonChange);

    if (previousGroup === newGroup) return { ok: true };

    if (previousGroup) {
      const { data: removeData } = await api.post(
        `/app-personas/helpers/desasignar-alumnos`,
        {
          id_alumnos: [studentId],
        }
      );

      if (removeData.e)
        return {
          ok: false,
          error: `No se pudo remover al alumno: ${removeData.e}`,
        };
      return { ok: true, data: { removeData } };
    }

    // const fechaCambio = new Date().toISOString().slice(0, 10);
    // const { data: changeData } = await api.post(`/app-personas/cambio-grupo`, {
    //   alumno: studentId,
    //   grupo_anterior: previousGroup,
    //   grupo_nuevo: newGroup,
    //   fecha_cambio: fechaCambio,
    //   motivo_cambio: reasonChange,
    // });

    // if (changeData.e)
    //   return {
    //     ok: false,
    //     error: `No se pudo cambiar al alumno de grupo: ${changeData.e}`,
    //   };

    // return { ok: true, data: { changeData } };
  } catch (error) {
    return { ok: false, error: error.message };
  }
};

export const postUnassignStudentToGroup = async (studentIds = []) => {
  try {
    console.log(studentIds);

    const { data: removeData } = await api.post(
      '/app-personas/helpers/desasignar-alumnos',
      {
        id_alumnos: studentIds,
      }
    );

    if (removeData.e)
      return {
        ok: false,
        error: `No se pudieron desasignar los alumnos: ${removeData.e}`,
      };
    return { ok: true, data: { removeData } };
  } catch (error) {
    return { ok: false, error: error.message };
  }
};

export const fetchPostAssignStudentsToGroup = async (
  groupId,
  studyPlanId,
  studentDetails
) => {
  try {
    const payload = {
      id_grupo: groupId,
      id_plan_estudios: studyPlanId,
      ids_alumnos: studentDetails.map((student) => student),
    };

    const { data } = await api.post(
      `/app-personas/helpers/asignacion-alumnos-a-grupo`,
      payload
    );

    if (data.error) {
      return { ok: false, error: data.error };
    }
    return { ok: true, data };
  } catch (error) {
    return { ok: false, error: error.message };
  }
};

/**
 * Asigna alumnos a un grupo
 * @param {Number} groupId Id de grupo al que se van a asignar los alumnos
 * @param {Number} studyPlanId Id de plan de estudios
 * @param {Array} studentsId Arreglo de ids de los alumnos a asignar
 * @returns Ok true o false
 */
export const postAssignStudentsToGroup = async (
  groupId,
  studyPlanId,
  studentsId = []
) => {
  try {
    const payload = {
      id_grupo: groupId,
      id_plan_estudios: studyPlanId,
      ids_alumnos: studentsId,
    };

    const { data } = await api.post(
      `/app-personas/helpers/asignacion-alumnos-a-grupo`,
      payload
    );

    if (data.error) {
      return { ok: false, error: data.error };
    }
    return { ok: true, data };
  } catch (error) {
    return { ok: false, error: error.message };
  }
};

export const fetchPostTransferStudents = async (
  sourceGroupId,
  destinationGroupId
) => {
  try {
    const { data } = await api.post(`/app-personas/helpers/trasladar-alumnos`, {
      id_grupo_origen: sourceGroupId,
      id_grupo_destino: destinationGroupId,
    });
    if (data.error) {
      return { ok: false, error: data.error };
    }
    return { ok: true, data };
  } catch (error) {
    return { ok: false, error: error.message };
  }
};
/**
 * Mueve algunos alumnos de un grupo a otro
 * @param {Array} studentsId Arreglo de ids de alumnos a mover
 * @param {Number} sourceGroupId Id de grupo destino
 * @param {Number} destinationGroupId Id de grupo destino
 * @param {Number} studyPlanId Id de plan de estudios
 * @returns ok true o false
 */
export const postTransferStudents = async (
  sourceGroupId,
  destinationGroupId,
  studentIds = [],
  studyPlanId = null
) => {
  try {
    const { data } = await api.post(`/app-personas/helpers/trasladar-alumnos`, {
      id_grupo_origen: sourceGroupId,
      id_grupo_destino: destinationGroupId,
      id_alumnos: studentIds,
      id_plan_estudios: studyPlanId,
    });
    if (data.error) {
      return { ok: false, error: data.error };
    }
    return { ok: true, data };
  } catch (error) {
    return { ok: false, error: error.message };
  }
};

/**
 * Mueve todos los alumnos de un grupo a otro
 * @param {Number} sourceGroupId Id de grupo origen
 * @param {Number} destinationGroupId Id de grupo destino
 * @returns ok true o false
 */
export const postPromoteStudents = async (
  sourceGroupId,
  destinationGroupId
) => {
  try {
    const { data } = await api.post(`/app-personas/helpers/avanzar-alumnos`, {
      id_grupo_origen: sourceGroupId,
      id_grupo_destino: destinationGroupId,
    });
    if (data.error) {
      return { ok: false, error: data.error };
    }
    return { ok: true, data };
  } catch (error) {
    return { ok: false, error: error.message };
  }
};

export const postAssignStudentToGroupNoSchoolControl = async (
  studentId = null,
  groupId = null
) => {
  try {
    const { data } = await api.post(
      '/app-personas/helpers/asignar-alumno-grupo',
      {
        id_alumno: studentId,
        id_grupo: groupId,
      }
    );
    if (data.error) {
      return { ok: false, error: data.error };
    }
    return { ok: true, data };
  } catch (error) {
    return { ok: false, error: error.message };
  }
};

export const postUnassignStudentToGroupNoSchoolControl = async (
  studentId = null,
  groupId = null
) => {
  try {
    const { data } = await api.post(
      '/app-personas/helpers/remove-alumnos-grupo',
      {
        id_alumno: studentId,
        id_grupo: groupId,
      }
    );
    if (data.error) {
      return { ok: false, error: data.error };
    }
    return { ok: true, data };
  } catch (error) {
    return { ok: false, error: error.message };
  }
};

/**
 * Asigna varios profesores a un grupo cuando control escolar no esté habilitado
 * @param {Number} details.id_grupo id de grupo
 * @param {Array} details.ids_profesores ids de profesores a asignar
 * @returns ok true o false
 */
export const postAssignTeachersToGroup = async (
  details = {
    id_grupo: null,
    ids_profesores: [],
    ids_materias: [],
  }
) => {
  try {
    const { data } = await api.post(
      '/app-personas/helpers/asignacion-profesores-a-grupo-por-lote',
      details
    );
    if (data.error) {
      return { ok: false, error: data.error };
    }
    return { ok: true, data };
  } catch (error) {
    return { ok: false, error: error.message };
  }
};

/**
 * Asigna varios profesores a un grupo cuando control escolar esté habilitado,
 * el proceso incluye crear materiasProfesor y agregarlas al grupo
 * @param {Number} details.id_grupo id de grupo
 * @param {Array} details.detalle_profesores ids de profesores y materias a asignar
 * @returns ok true o false
 */
export const postAssignTeachersAndSubjectsToGroup = async (
  details = { id_grupo: null, detalle_profesores: [] }
) => {
  try {
    const { data } = await api.post(
      '/app-personas/helpers/asignacion-profesores-a-grupo-por-lote',
      details
    );
    if (data.error) {
      return { ok: false, error: data.error };
    }
    return { ok: true, data };
  } catch (error) {
    return { ok: false, error: error.message };
  }
};
