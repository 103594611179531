<template>
  <v-row>
    <v-col
      v-if="showLabel"
      class="py-0" 
      cols="12"
      md="4"
      lg="4"
      xl="4"
    >
      <v-subheader>
        {{ label }}<span v-if="isMandatory" class="error--text">*</span>
      </v-subheader>
    </v-col>
    <v-col
      class="py-0"
      :cols="colsWithoutLabel"
    >
      <v-select
        v-model="innerValue"
        dense
        outlined
        :rules="rules"
        v-bind="$attrs"
        v-on="$listeners"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    rules: {
      type: [Array, String],
      default: null
    },
    value: {
      type: null
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    isMandatory: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      innerValue: null
    }
  },
  computed: {
    colsWithoutLabel() {
      return this.showLabel ? (this.$vuetify.breakpoint.mdAndUp ? '8' : '12') : '12'
    }
  },
  watch: {
    // Handles internal model changes.
    innerValue (newVal) {
      this.$emit('input', newVal);
    },
    // Handles external model changes.
    value (newVal) {
      this.innerValue = newVal;
    }
  },
  created () {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
}
</script>

<style>
  .left{
    padding: 0 0;
  }
  .right{
    padding: 0 5;
  }
</style>