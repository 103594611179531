import {
  deleteItem,
  getItem,
  postItem,
  putItem,
} from '../../../api/api-methods';

const emptyPagination = {
  currentPage: 1,
  lastPage: 1,
  previousPage: 1,
  nextPage: 1,
  offset: 0,
  limit: 5,
  count: 0,
};
export const removeStudentGroup = async (studentId, groupId) => {
  const URL = '/app-personas/helpers/remove-alumnos-grupo';
  try {
    const { data } = await postItem(URL, {
      id_alumno: studentId,
      id_grupo: groupId,
    });
    return { data, ok: true };
  } catch (error) {
    return { ok: false, message: error?.toString() };
  }
};

export const reactivateStudent = async (studentId) => {
  const URL = '/app-personas/helpers/reactivar-alumno';
  try {
    const { data } = await postItem(URL, {
      id_alumno: studentId,
    });
    return { data, ok: true };
  } catch (error) {
    return { ok: false, message: error?.toString() };
  }
};

export const deleteStudent = async (studentId) => {
  const URL = '/app-personas/alumno';
  try {
    const { data } = await deleteItem(URL, studentId);
    return { data, ok: true };
  } catch (error) {
    return { ok: false, message: error?.toString() };
  }
};

export const postStudent = async (studentObj) => {
  try {
    const URL = '/app-personas/alumno';
    const data = await postItem(URL, studentObj);
    return { data, ok: true };
  } catch (error) {
    return { ok: false, message: error?.toString() };
  }
};

export const putStudent = async (studentObj, studentId) => {
  try {
    const URL = '/app-personas/alumno/';
    const { data } = await putItem(`${URL}${studentId}`, studentObj);
    return { data, ok: true };
  } catch (error) {
    return { ok: false, message: error?.toString() };
  }
};

export const fetchStudentByName = async (
  name = '',
  pagination = emptyPagination,
  systemStatus = true,
  institution = null,
  schoolLevel = null,
  group = null
) => {
  const institutionFilter =
    institution !== null ? `?institucion_educativo=${institution}` : '';
  const nameFilter =
    name !== '' && name !== null ? `&nombre_completo=${name}` : '';
  const schoolLevelFilter = schoolLevel
    ? `&nivel_educativo=${schoolLevel}`
    : '';
  const groupFilter = group ? `&grupo=${group}` : '';
  const systemStatusFilter =
    systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';

  const offsetFilter =
    pagination.offset !== 0 ? `&offset=${pagination.offset}` : '';
  const limitFilter =
    pagination.limit !== null ? `&limit=${pagination.limit}` : '';

  try {
    // const URL = `/app-personas/helpers/buscar-alumno-por-nombre-completo/${name}/${systemStatus}/${institution}?${limitFilter}${offsetFilter}`;
    const URL =
      `/app-personas/helpers/buscar-alumno-por-nombre-completo`.concat(
        institutionFilter,
        nameFilter,
        schoolLevelFilter,
        groupFilter,
        systemStatusFilter,
        offsetFilter,
        limitFilter
      );
    const response = await getItem(URL);
    // console.log('fetchStudentByName', response);
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo encontrar alumno por nombre. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return { ok: false, message: error?.toString() };
  }
};

export const findGroupAssignedToStudent = (
  studentId,
  groups = [],
  isEarlyStimulation = false
) => {
  return groups.find(
    (group) =>
      group.alumnos.some((student) => student.id === studentId) &&
      (isEarlyStimulation ? group.ciclo_escolar === null : true)
  );
};

export const fetchGetGroupByStudent = async (student, institution) => {
  try {
    const URL = `/app-personas/filters/grupo?alumnos=${student}&institucion_educativa=${institution}&estatus_sistema=true`;
    const data = await getItem(URL);
    return data;
  } catch (error) {
    return { ok: false, message: error?.toString() };
  }
};

export const postActivateEarlyStimulation = async (studentId) => {
  try {
    const URL = '/app-personas/helpers/activar-alumno-estimulacion-temprana';
    const response = await postItem(URL, {
      id_alumno: studentId,
    });
    // console.log('postActivateEarlyStimulation', response);
    if (response.e) {
      return {
        ok: false,
        message:
          'No se pudo activar al alumno en estimulacion temprana. ' +
          response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return { ok: false, message: error?.toString() };
  }
};

export const postCreateBoletaReport = async (studentId, startDate, endDate) => {
  try {
    const URL = '/app-personas/helpers/crear-reporte-boleta';
    const response = await postItem(URL, {
      id_alumno: studentId,
      fecha_inicio_periodo: startDate,
      fecha_fin_periodo: endDate,
    });
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo obtener el reporte. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return { ok: false, message: error?.toString() };
  }
};

export const postDeactivateEarlyStimulation = async (studentId) => {
  try {
    const URL = '/app-personas/helpers/desactivar-alumno-estimulacion-temprana';
    const response = await postItem(URL, {
      id_alumno: studentId,
    });
    // console.log('postDeactivateEarlyStimulation', response);
    if (response.e) {
      return {
        ok: false,
        message:
          'No se pudo desactivar al alumno de estimulacion temprana. ' +
          response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return { ok: false, message: error?.toString() };
  }
};

export const postAssignStudenToGroup = async (studentId, groupId) => {
  try {
    const URL = '/app-personas/helpers/asignar-alumno-grupo';
    const response = await postItem(URL, {
      id_alumno: studentId,
      id_grupo: groupId,
    });
    // console.log('postAssignStudenToGroup', response);
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo asignar el alumno al grupo. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return { ok: false, message: error?.toString() };
  }
};

export const postRemoveStudenOfGroup = async (studentId, groupId) => {
  try {
    const URL = '/app-personas/helpers/remove-alumnos-grupo';
    const response = await postItem(URL, {
      id_alumno: studentId,
      id_grupo: groupId,
    });
    // console.log('postRemoveStudenOfGroup', response);
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo remover al alumno del grupo. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return { ok: false, message: error?.toString() };
  }
};

export const fetchPostAssignmentTuition = async (studentId, tuitionId) => {
  try {
    const URL = '/app-personas/helpers/asignar-colegiatura';
    const response = await postItem(URL, {
      id_alumno: studentId,
      id_colegiatura: tuitionId,
    });
    if (response.e) {
      return {
        ok: false,
        message: 'Error: ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return { ok: false, message: error?.toString() };
  }
};
export const fetchPostUpdateChangeTuition = async (studentId, tuitionId) => {
  try {
    const URL =
      '/app-administracion/helpers/actualizar-adeudos-cambio-colegiatura';
    const response = await postItem(URL, {
      id_alumno: studentId,
      nueva_colegiatura_id: tuitionId,
    });
    if (response.e) {
      return {
        ok: false,
        message: 'Error: ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return { ok: false, message: error?.toString() };
  }
};
export const fetchInvoiceUsage = async (usoFactura) => {
  const URL = `/app-personas/uso-factura/${usoFactura}`;
  try {
    const res = await getItem(URL);
    return { res, ok: true };
  } catch (error) {
    return { ok: false, message: error?.toString() };
  }
};

export const fetchPostStudentFamilyMembers = async (studentId) => {
  try {
    const URL = '/app-personas/helpers/obtener-familia-alumno';
    const response = await postItem(URL, {
      id_alumno: studentId,
    });
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo obtener los familiares del alumno. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return { ok: false, message: error?.toString() };
  }
};

export const biuldStudentGroup = async (group) => {
  if (!group) return null;
  const URL = `/app-personas/grupo/${group}`;
  try {
    const res = await getItem(URL);
    // console.log('biuldStudentGroup res', res);

    return res;
  } catch (error) {
    console.error('Error fetching group:', error);
    return { ok: false, message: error?.toString() };
  }
};

export const fetchWithdrawStudent = async ({
  alumno,
  tipo_movimiento,
  fecha_movimiento,
  motivo,
  institucion_educativa,
}) => {
  if (
    !alumno ||
    !tipo_movimiento ||
    !fecha_movimiento ||
    !motivo ||
    !institucion_educativa
  ) {
    return { ok: false, message: 'Todos los campos son obligatorios.' };
  }

  try {
    const URL = '/app-personas/alta-baja-alumno';
    const response = await postItem(URL, {
      alumno,
      tipo_movimiento,
      fecha_movimiento,
      motivo,
      institucion_educativa,
    });

    if (response.error) {
      return {
        ok: false,
        message: response.error,
      };
    }

    return { ok: true, data: response };
  } catch (error) {
    return { ok: false, message: error?.toString() };
  }
};
